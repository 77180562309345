import * as fuzzball from 'fuzzball';
document.addEventListener('DOMContentLoaded', () => {
  const searchInput = document.querySelector('.search-input input');
  const searchButton = document.querySelector('.search-input button');
  const dropdown = document.querySelector('.autocomplete-dropdown');
  const faqsMain = document.querySelector('.faqs-main');
  const allFAQs = Array.from(document.querySelectorAll('.faqs_container .accordion'));

  // Function to filter FAQs
  function filterFAQs(query) {
    const results = getMatchingFAQs(query);

    // Clear existing FAQs
    faqsMain.innerHTML = '';

    // Create a new wrapper div with the class "faqs_container"
    const wrapper = document.createElement('div');
    wrapper.className = 'faqs_container';

    // Append matching FAQs to the wrapper
    results.forEach(({ faq }) => {
      wrapper.appendChild(faq.cloneNode(true));
    });

    // Append the wrapper to the faqs-main container
    faqsMain.appendChild(wrapper);
  }

  // Function to get matching FAQs using Fuzzball
  function getMatchingFAQs(query) {
    if (!query.trim()) return [];

    const results = [];
    allFAQs.forEach((faq) => {
      const title = faq.querySelector('.accordion_title').textContent.trim();
      const score = fuzzball.partial_ratio(query, title); // Fuzzball scoring
      if (score > 70) { // Adjust threshold as needed
        results.push({ faq, score, title });
      }
    });

    // Sort results by score
    return results.sort((a, b) => b.score - a.score);
  }

  // Function to update dropdown suggestions
  function updateDropdown(query) {
    const results = getMatchingFAQs(query);

    // Clear dropdown
    dropdown.innerHTML = '';
    dropdown.style.display = results.length ? 'block' : 'none';

    // Add results to dropdown
    results.forEach(({ title }) => {
      const item = document.createElement('div');
      item.textContent = title;
      item.className = 'autocomplete-item';

      // Handle click on dropdown item
      item.addEventListener('click', () => {
        searchInput.value = title;
        filterFAQs(title); // Filter FAQs based on the selected suggestion
        dropdown.style.display = 'none';
      });

      dropdown.appendChild(item);
    });
  }

  // Event listener for typing in the search input
  searchInput.addEventListener('input', () => {
    const query = searchInput.value;
    updateDropdown(query);
  });

  // Event listener for clicking the search button
  searchButton.addEventListener('click', () => {
    const query = searchInput.value;
    filterFAQs(query);
  });

  // Close dropdown if clicked outside
  document.addEventListener('click', (e) => {
    if (!document.querySelector('.search-input').contains(e.target)) {
      dropdown.style.display = 'none';
    }
  });
});
